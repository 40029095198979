import React, { Component } from 'react';
// import { HashRouter, Route, Switch } from 'react-router-dom';
import { HashRouter, BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ActCreators } from "../src/redux/bindActionCretor";
import { connect } from 'react-redux';

import './scss/style.scss';
import 'react-notifications/lib/notifications.css';

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
// const Dashboard = React.lazy(() => import('./views/dashboard/DashboardDetail_New'));
const Dashboard = React.lazy(() => import('./views/dashboard/DashboardDetail_New1'));
const Gateway = React.lazy(() => import('./views/gateway/Gateway'));
const Sensor = React.lazy(() => import('./views/sensor/Sensor'));
const Service = React.lazy(() => import('./views/service/Service'));
const Subscription = React.lazy(() => import('./views/subscription/subscription'));
class App extends Component {

	render() {
		console.log("Routing Data:-", this.props.isLoginDetails, this.props.isCompanyDetails)
		return (
			// <HashRouter>
			// 	<React.Suspense fallback={loading}>
			// 		<Switch>
			// 			<Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
			// 			<Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
			// 			<Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
			// 			<Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
			// 			<Route path="/" name="Home" render={props => <TheLayout {...props} />} />
			// 		</Switch>
			// 	</React.Suspense>
			// </HashRouter>

			<HashRouter>
				<React.Suspense fallback={loading}>

					<Switch>
						<Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
						<Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
						<Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
						<Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />

						{Object.keys(this.props.isLoginDetails).length === 0 ?
							<Redirect from="/" to="/login" />
							:
							<>
								<Route path="/" name="Home" render={props => <TheLayout {...props} />} />
							</>
						}
					</Switch>
				</React.Suspense>
			</HashRouter>
		);
	}
}
const mapStateToProps = state => {
	return {
		isCompanyDetails: state.companyDetails,
		isLoginDetails: state.logindata,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onCheckCompanyDetails: () => dispatch(ActCreators.CompanyDetails),
		onCheckLoginDetails: () => dispatch(ActCreators.Login),
	};

}
export default connect(mapStateToProps, mapDispatchToProps)(App);
