export function Login(res) {
  return { type: "Login", res };
}

export function CompanyDetails(res) {
  return { type: "CompanyDetails", res };
}

export function Loader(res) {
  return { type: "Loader", res };
}

export function Logout() {
  return { type: "Loader" };
}

export function subscriptionDetails(res) {
  return { type: "subscriptionDetails", res };
}

export function Company(res) {
  return { type: "CompanyId", res };
}

export function GatewayList(res) {
  return { type: "GatewayList", res };
}
export function SensorList(res) {
  return { type: "SensorList", res };
}
export function DateAndTime(res) {
  return { type: "DateAndTime", res };
}
export function isShowCompanySelection(res) {
  return { type: "isShowCompanySelection", res };
}
export function pubsubReading(res) {
  return { type: "pubsubReading", res };
}
export function historyData(res) {
  return { type: "historyData", res };
}
export function realTimeData(res) {
  return { type: "realTimeData", res };
}
export function clearDashboardData(res) {
  return { type: "clearDashboardData", res };
}
export function AWSSubscribe(res) {
  return { type: "AWSSubscribe", res };
}
export function isSetGraphData(res) {
  return { type: "isSetGraphData", res };
}
export function lastSelectedComId(res) {
  return { type: "lastSelectedComId", res };
}

export function dashboardId(res) {
  return { type: "dashboardId", res };
}

export function favoriteDashboardId(res) {
  return { type: "favoriteDashboardId", res };
}

export function isFromHome(res) {
  return { type: "isFromHome", res };
}
