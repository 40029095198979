import { REHYDRATE } from "redux-persist";
import moment from "moment";
import _ from "lodash";

var initState = {
  logindata: {},
  companyDetails: {},
  loader: false,
  sidebarShow: "responsive",
  isShowCompanySelection: true,
  pubsubReading: {
    reading: "",
    name: "",
    unit: "",
    currentTimestamp: null,
  },
  realTimeData: {
    graphType: "",
    timeFrom: {},
    readingCount: {},
    objRealTime: {
      TimeNumber: [],
      labels: [],
      datasets: [
        {
          sensor_name: "",
          values: [],
        },
      ],
    },
  },
  historyData: {
    graphType: "",
    TimeNumber: null,
    labels: null,
    datasets: [
      {
        sensor_name: "",
        values: null,
      },
    ],
  },
  clearDashboardData: {},
  AWSSubscribe: { subscribeTopic: null, subscribeTopicName: null },
  isSetGraphData: true,
  lastSelectedComId: "",
  dashboardId: "",
  favoriteDashboardId: "",
  isFromHome: false,
};

function loginreducer(state = initState, action) {
  switch (action.type) {
    case "Login":
      console.log("LOgin reducer:-", action.res);
      return {
        ...state,
        logindata: action.res,
        isShowCompanySelection: true,
      };
    case "set":
      return {
        ...state,
        sidebarShow: action.sidebarShow,
      };
    case "CompanyDetails":
      return {
        ...state,
        companyDetails: action.res,
      };

    case "Loader":
      return {
        ...state,
        loader: action.res,
      };

    case "Logout":
      return {
        ...state,
        logindata: { logout: true },
        companyDetails: { logout: true },
        loader: false,
      };
    case "subscriptionDetails":
      return {
        ...state,
        subscriptionDetails: action.res,
      };
    case "CompanyId":
      return {
        ...state,
        CompanyId: action.res,
      };
    case "GatewayList":
      return {
        ...state,
        GatewayList: action.res,
      };
    case "SensorList":
      return {
        ...state,
        SensorList: action.res,
      };
    case "DateAndTime":
      return {
        ...state,
        DateAndTime: action.res,
      };
    case "isShowCompanySelection":
      return {
        ...state,
        isShowCompanySelection: action.res,
      };
    case "pubsubReading":
      return {
        ...state,
        pubsubReading: action.res,
      };
    case "historyData":
      return {
        ...state,
        historyData: action.res,
      };
    case "realTimeData":
      return {
        ...state,
        realTimeData: action.res,
      };
    case "clearDashboardData":
      return {
        ...state,
        clearDashboardData: action.res,
      };
    case "lastSelectedComId":
      return {
        ...state,
        lastSelectedComId: action.res,
      };
    case "AWSSubscribe":
      const tmpState = _.cloneDeep(state);
      // console.log("tmpState:-", tmpState);
      // console.log("action.res:-", action.res)

      return {
        ...state,
        AWSSubscribe: Object.assign({}, tmpState.AWSSubscribe, action.res),
      };
    case "isSetGraphData":
      return {
        ...state,
        isSetGraphData: action.res,
      };
    case "dashboardId":
      return {
        ...state,
        dashboardId: action.res,
      };
    case "isFromHome":
      return {
        ...state,
        isFromHome: action.res,
      };
    case "favoriteDashboardId":
      return {
        ...state,
        favoriteDashboardId: action.res,
      };
    case REHYDRATE:
      return {
        ...state,
        logindata:
          action.payload && action.payload.logindata
            ? action.payload.logindata
            : {},
        companyDetails:
          action.payload && action.payload.companyDetails
            ? action.payload.companyDetails
            : {},
      };
    default:
      return {
        ...state,
      };
  }
}

export const reducer = loginreducer;
