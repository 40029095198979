import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import thunk from 'redux-thunk';
import { reducer } from './reducer'
import { createLogger } from 'redux-logger'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';

const logger = createLogger({
});

const persistConfig = {
	key: 'root',
	storage: storage,
	stateReconciler: autoMergeLevel2,
};
const persistedReducer = persistReducer(persistConfig, reducer);

//const store=createStore(reducer,{},  applyMiddleware(thunk,logger));
export const store = createStore(persistedReducer, {}, applyMiddleware(thunk));
export const persistor = persistStore(store);

/*export default () => {
	let store = createStore(persistedReducer,{},  applyMiddleware(thunk,logger));
	let persistor = persistStore(store)
	return { store, persistor }
}*/

//export default store;